const resources = [
    {
        title: "Types of Therapy",
        mainLink: "https://www.psychologytoday.com/us/types-of-therapy",
    },
    {
        title: "Caring for Your Mental Health",
        mainLink: "https://www.nimh.nih.gov/health/topics/caring-for-your-mental-health",
    },
    {
        title: "Mental Health Helplines",
        mainLink: "https://www.facebook.com/InTouchCrisisLine/",
    }
];

export default resources;
